<template>
        <el-dialog title="Arii protejate" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                <el-col :md='8'>
                    <el-form-item label='Nume' prop="Nume" >
                        <el-input  class='full-width' v-model='selectedObject.Nume' />
                    </el-form-item>
                </el-col>
                 <el-col :md='8'>
                    <el-form-item label='Cod NATIONAL' prop="CodNational">
                        <el-input  class='full-width' v-model='selectedObject.CodNational' />
                    </el-form-item>
                </el-col>  
                <el-col :md='8'>
                    <el-form-item label='Cod INSPIRE' prop="CodInspire">
                        <el-input  class='full-width' v-model='selectedObject.CodInspire' />
                    </el-form-item>
                </el-col>       
                <el-col :md='8'>
                    <el-form-item label='Tip' prop="Tip">
                        <el-input  class='full-width' v-model='selectedObject.Tip' />
                    </el-form-item>
                </el-col>           
                <el-col :md='8'>
                    <el-form-item label='Judet' prop="Judet">
                        <el-input  class='full-width' v-model='selectedObject.Judet' />
                    </el-form-item>
                </el-col>      

                <el-col :md='8'>
                    <el-form-item label='Suprafata (ha)' prop="Suprafata">
                       <el-input
                        type="number"
                        v-model="selectedObject.Suprafata">
                        </el-input>
                    </el-form-item>
                </el-col>     

            </el-row>
        </el-form>

        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Activitati_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: { Nume: '' , CodNational:'', CodInspire:'', Tip:'', Suprafata:'', Judet: '' },    
                selectedObject:{},              
                Info:{ planuri_management:[] },
                                   
                rules: {
                    Nume: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    CodInspire: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    CodNational: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Tip: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Judet: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Suprafata: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate));
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("arii_protejate/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("arii_protejate/get_info_for_dialog" );
                this.Info.planuri_management = response.Planuri_management
            },
            save: async function() {
               
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("arii_protejate/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>